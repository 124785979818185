import React from 'react'
import styled from 'styled-components'
import {AiFillHome, AiFillCar} from "react-icons/ai"
import {FaHeartbeat} from "react-icons/fa"
import {BsFillDisplayFill} from "react-icons/bs"
import {MdOutlineAirplanemodeActive} from "react-icons/md"
import handphone from "../Assets/handphone.png"

const Explore = () => {
  return (
    <Container>
        <Wrapper>
            <Top><h1>Lorem ipsum dolor sit.</h1></Top>
            <Cardshold>
                <Card>
                    <Up>Lorem, ipsum dolor.</Up>
                    <Down>
                        <Circle>
                            <AiFillHome />
                        </Circle>
                    </Down>
                </Card>
                <Card2>
                    <Up>Lorem ipsum dolor sit.</Up>
                    <Down>
                        <Circle2>
                            <FaHeartbeat />
                        </Circle2>
                    </Down>
                </Card2>
                <Card3>
                    <Up>Lorem ipsum dolor sit.</Up>
                    <Down>
                        <Circle3>
                            <AiFillCar />
                        </Circle3>
                    </Down>
                </Card3>
                <Card4>
                    <Up>Lorem ipsum dolor sit.</Up>
                    <Down>
                        <Circle4>
                            <BsFillDisplayFill />
                        </Circle4>
                    </Down>
                </Card4>
                <Card5>
                    <Up>Lorem ipsum dolor sit.</Up>
                    <Down>
                        <Circle5>
                            <MdOutlineAirplanemodeActive />
                        </Circle5>
                    </Down>
                </Card5>
            </Cardshold>
            <Phonehold>
                <Left>
                    <Pay>
                        <h2>Lorem ipsum dolor sit, amet consectetur adipisicing.</h2>
                    </Pay>
                    <P>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quas quis vel eaque nisi fugit repudiandae pariatur.
                    </P>
                    <Button>lorem</Button>
                </Left>
                <Right>
                    <Img src={handphone} />
                </Right>
            </Phonehold>
        </Wrapper>
    </Container>
  )
}

export default Explore
const Img = styled.img`
    height: 570px;
    @media screen and (max-width: 320px) {
        height: 250px;
    }
    @media screen and (max-width: 375px) {
        height: 270px;
        margin-top: 10px;
    }
    @media screen and (max-width: 425px) {
        height: 350px;
        margin-top: 10px;
    }
`
const Right = styled.div`
    width: 50%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    @media screen and (max-width: 320px) {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 15px;
    }
    @media screen and (max-width: 904px) {
        width: 100%;
        /* justify-content: flex-end; */
    }
`
const Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 43px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: #3E4095;
    color: white;
    margin-top: 250px;
    margin-left: 80px;
    @media screen and (max-width: 904px) {
        margin-top: 30px;
        margin-left: 5px;
    }
`
const P = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 1.50rem;
    margin-left: 80px;
    width: 80%;
    @media screen and (max-width: 904px) {
        width: 100%;
        margin-left: 5px;
    }
    @media screen and (max-width: 375px) {
        text-align: center;
    }
    @media screen and (max-width: 425px) {
        text-align: center;
    }
`
const Pay = styled.div`
    width: 70%;
    margin-left: 80px;
    flex-wrap: wrap;
    h2{
        font-weight: 700;
        font-size: 38px;
        line-height: 2.5rem;
        @media screen and (max-width: 904px) {
        font-size: 30px;
        color: #3d3d3d;
        @media screen and (max-width: 320px) {
        font-size: 27px;
    }
    @media screen and (max-width: 375px) {
        font-size: 25px;
    }
    }
    }
    @media screen and (max-width: 904px) {
        width: 100%;
        margin-left: 5px;
    }
    @media screen and (max-width: 320px) {
        text-align: center;
    }
    @media screen and (max-width: 375px) {
        text-align: center;
    }
    @media screen and (max-width: 425px) {
        text-align: center;
    }
`
const Left = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    /* background-color: red; */
    @media screen and (max-width: 904px) {
        width: 100%;
    }
`
const Phonehold = styled.div`
    width: 88%;
    display: flex;
    align-items: center;
    background-color: #FAFAFA;
    margin-top: 60px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding-top: 60px;
    flex-wrap: wrap;
    border-radius: 20px;
    @media screen and (max-width: 904px) {
        width: 100%;
        padding: 10px;
    }
`
const Circle5 = styled.div`
    width: 75px;
    height: 75px;
    display: flex;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    background-color: #FBDB5A;
    color: #99CEB0;
    font-size: 35px;
    margin-right: 20px;
`
const Card6 = styled.div`
    display: flex;
    flex-direction: column;
    width: 220px;
    padding-bottom: 15px;
    background-color: #FACC15;
    border-radius: 10px;
`
const Card5 = styled.div`
    display: flex;
    flex-direction: column;
    width: 220px;
    padding-bottom: 15px;
    background-color: #FACC15;
    border-radius: 10px;
    @media screen and (max-width: 320px) {
        width: 100%;
        margin-top: 10px;
    }
    @media screen and (max-width: 375px) {
        width: 100%;
        margin-top: 10px;
    }
    @media screen and (max-width: 425px) {
        width: 100%;
        margin-top: 10px;
    }
`
const Circle4 = styled.div`
    width: 75px;
    height: 75px;
    display: flex;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    background-color: #51539F;
    color: #99CEB0;
    font-size: 35px;
    margin-right: 20px;
`
const Card4 = styled.div`
    display: flex;
    flex-direction: column;
    width: 220px;
    padding-bottom: 15px;
    background-color: #3E4095;
    border-radius: 10px;
    @media screen and (max-width: 320px) {
        width: 100%;
        margin-top: 10px;
    }
    @media screen and (max-width: 375px) {
        width: 100%;
        margin-top: 10px;
    }
    @media screen and (max-width: 425px) {
        width: 100%;
        margin-top: 10px;
    }
`
const Circle3 = styled.div`
    width: 75px;
    height: 75px;
    display: flex;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    background-color: #8352AB;
    color: #99CEB0;
    font-size: 35px;
    margin-right: 20px;
`
const Card3 = styled.div`
    display: flex;
    flex-direction: column;
    width: 250px;
    padding-bottom: 15px;
    background-color: #763FA2;
    border-radius: 10px;
    @media screen and (max-width: 320px) {
        width: 100%;
        margin-top: 10px;
    }
    @media screen and (max-width: 375px) {
        width: 100%;
        margin-top: 10px;
    }
    @media screen and (max-width: 425px) {
        width: 100%;
        margin-top: 10px;
    }
`
const Circle2 = styled.div`
    width: 75px;
    height: 75px;
    display: flex;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    background-color: #E02D98;
    color: #99CEB0;
    font-size: 35px;
    margin-right: 20px;
`
const Card2 = styled.div`
    display: flex;
    flex-direction: column;
    width: 220px;
    padding-bottom: 15px;
    background-color: #DD168D;
    border-radius: 10px;
    @media screen and (max-width: 320px) {
        width: 100%;
        margin-top: 10px;
    }
    @media screen and (max-width: 375px) {
        width: 100%;
        margin-top: 10px;
    }
    @media screen and (max-width: 425px) {
        width: 100%;
        margin-top: 10px;
    }
`
const Circle = styled.div`
    width: 75px;
    height: 75px;
    display: flex;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    background-color: #37A064;
    color: #99CEB0;
    font-size: 35px;
    margin-right: 20px;
    /* @media screen and (max-width: 320px) {
        width: 50px;
        height: 50px;
        font-size: 25px;
        margin-right: 0px;
        margin-top: -10px;
    } */
`
const Down = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    /* @media screen and (max-width: 320px) {
        justify-content: center;
        display: flex;
    } */
`
const Up = styled.div`
    font-size: 16px;
    line-height: 1.25rem;
    color: #fff;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 15px;
    
`
const Card = styled.div`
    display: flex;
    flex-direction: column;
    width: 220px;
    padding-bottom: 15px;
    background-color: #219653;
    border-radius: 10px;
    @media screen and (max-width: 320px) {
        width: 100%;
    }
    @media screen and (max-width: 375px) {
        width: 100%;
    }
    @media screen and (max-width: 425px) {
        width: 100%;
    }
`
const Cardshold = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
`
const Top = styled.div`
    h1{
        font-weight: 700;
        font-size: 33px;
        line-height: 2rem;
        color: #3d3d3d;
        @media screen and (max-width: 320px) {
        line-height: 2.5rem;
        font-size: 27px;
    }
    }
    @media screen and (max-width: 320px) {
        text-align: center;
    }
`
const Wrapper = styled.div`
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* background-color: red; */
    justify-content: center;
    align-items: center;
`

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FAFAFA;
`